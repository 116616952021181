import React, { useState, useEffect } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import {formatDuration} from "../../util/helper";

function CurrentTicketComponent() {
  const [showTimer, setShowTimer] = useState(false); // Состояние для отображения секундомера
  const [timerValue, setTimerValue] = useState(0); // Состояние для значения секундомера

  useEffect(() => {
    let interval;

    if (RIS._me.my_process_process_examination_ticket?.code) {
      // Если есть значение в RIS._me.my_process_process_examination_ticket.code,
      // показываем секундомер и обновляем его каждую секунду
      setShowTimer(true);
      setTimerValue(0);
      interval = setInterval(() => {
        setTimerValue((prevValue) => prevValue + 1);
      }, 1000);
    } else {
      // Если значения нет, скрываем секундомер и очищаем интервал
      setShowTimer(false);
      clearInterval(interval);
    }

    return () => {
      // Очищаем интервал при размонтировании компонента
      clearInterval(interval);
    };
  }, [RIS._me.my_process_process_examination_ticket?.code]);

  return (
    <Grid item xs={6}>
      <Box pb={2}>Текущий талон</Box>
      {showTimer ? (
        <div>
          <Typography variant='h3' style={{ color: "green" }}>
            {RIS._me.my_process_wait_examination_ticket?.code ||
              RIS._me.my_process_process_examination_ticket?.code ||
              '-'}
          </Typography>
          <Typography variant='h4'>
            {formatDuration(timerValue)}
          </Typography>
        </div>
      ) : (
        <Typography variant='h3'>
          {RIS._me.my_process_wait_examination_ticket?.code ||
            RIS._me.my_process_process_examination_ticket?.code ||
            '-'}
        </Typography>
      )}
    </Grid>
  );
}

export default CurrentTicketComponent;