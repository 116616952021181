import Component from '../../util/ControlledComponent';
import View from './View';
import {formatDuration} from '../../util/helper'

function isExaminationUrl() {
  return window.location.href.includes('examinations');
}

export const inviteNextExaminationTicket = (com) => {
  // com.setState({invitingExaminationNext: true})
  // что-бы не срабатывало на разных страницах, а только на странице мед осмотра
  if (isExaminationUrl()) {
    Api.call('invite-next-examination-ticket/' + RIS._me.active_examination_queue.uuid, {
      method: 'post',
      post: {
        reg: RIS._me.current_examination.id,
      }
    }).then((data) => {
      RIS._me.process_wait_examination_ticket = data.currentTicket;
      RIS._me.my_wait_wait_examination_ticket = data.nextTicket;
      RIS._me.is_new_ticket = false;
      // setTimeout(() => {
      //   com.setState({invitingExaminationNext: false})
      // }, 2 * 1000);
    });
  }
};

RIS.controllers.ExaminationDetail = function (com) {
  let self = this;
  com.state = {
    id: com.props.id || com.props.match.params.id,
    loading: true,
    tab: 'my_tickets',
    item: {},
    mySpecialities: [],
    myOffice: '',
    doctorActive: false,
    activeExaminationQueue: null,
    invitingExaminationNext: false, // пригласить следующего
    officesDictionary: [],
    repeatExaminationAvailable: true,
    returnToQueueExaminationTicketAvailable: true,
    inviteNextExaminationTicketAvailable: true,
    lost_examination_tickets: true,

    log_allowed: false,
  };
  extend(self, {
    init: () => {
      RIS.setTitle('Загрузка');
      RIS.addPermissions(com);
      Api.call('examination-offices/' + com.state.id, {query: {reg: com.state.id}}).then((list) =>
        com.setState({officesDictionary: list}),
      );
      self.load();
    },
    load: () => {
      com.setState({loading: true});
      RIS.setPageTitle("Электронный медосмотр");
      Api.read({
        type: 'examinations',
        id: com.state.id,
        query: {
          reg: com.state.id,
        },
      }).then((data) => {
        RIS.me().then((my) => {
          let can_edit = false,
            can_add = RIS.user.is('admin'),
            log_allowed = false,
            p = my['@permissions'];

          if (my.id !== data.id && RIS.config('allowed_saddle').indexOf(my.id) > -1) com.state.can_saddle = true;
          if (p.level >= 4 || my.examinations.includes(com.state.id)) can_edit = true;
          if (p.level >= 3) log_allowed = true;

          RIS._me.current_examination = data;
          RIS._me.all_examination_tickets = data.todayTicketsProcess;
          RIS._me.my_examination_tickets = data.todayMyTicketsWaiting;
          RIS._me.my_tickets_future = data.myTicketsFuture;
          RIS._me.other_examination_tickets = data.todayOtherTicketsWaiting;
          RIS._me.my_tickets_done_count = data.todayMyTicketsDoneCount;
          RIS._me.my_tickets_done = data.myTicketsDone;
          RIS._me.my_wait_wait_examination_ticket = data.myWaitWaitExaminationTicket;
          RIS._me.my_process_wait_examination_ticket = data.myProcessWaitExaminationTicket;
          RIS._me.my_process_process_examination_ticket = data.myProcessProcessExaminationTicket;
          RIS._me.lost_examination_tickets = data.lostExaminationTickets;
          RIS._me.my_average_reception_time = formatDuration(data.myAverageReceiptTime) ?? '00:00';
          RIS._me.my_reception_count = data.myReceptionCount;
          RIS._me.all_today_done = data.allTodayDone;
          RIS._me.all_today_waiting = data.allTodayWaiting;
          RIS._me.all_avg_waiting_time = formatDuration(data.allAvgWaitingTime) ?? '00:00';

          com.setState({
            item: data,
            can_edit: can_edit,
            activeExaminationQueue: RIS._me.active_examination_queue,
            myOffice: RIS._me.active_examination_queue ? RIS._me.active_examination_queue.office_id : '',
            mySpecialities: RIS._me.active_examination_queue ? RIS._me.active_examination_queue.specialities : [],
            my_examination_start_time: data.myStartExaminationTime
          });

          if (RIS._me.active_examination_queue) {
            com.setState({doctorActive: true});
          }
          com.setState({
            loading: false,
          });
        });
      });
      RIS.setTitle('Электронный медосмотр');
    },
    inviteNextExaminationTicket: () => {
      if (com.state.inviteNextExaminationTicketAvailable) {
        self.setState('inviteNextExaminationTicketAvailable', false);
        inviteNextExaminationTicket(com)
        setTimeout(function () {
          self.setState('inviteNextExaminationTicketAvailable', true);
        }, 10 * 1000)
      }
    },
    setState: function (key, value) {
      com.setState({[key]: value});
    },
    setDoctorActive: (doctorActive, setUser = null) => {
      // Если доктор ведет прием, то отключаем его
      if (doctorActive) {
        Api.call('disconnect-examination-queue', {
          method: 'post',
          post: {
            queue_uuid: RIS._me.active_examination_queue.uuid,
            reg: com.state.id,
            examination_id: com.state.id,
          }
        }).then(() => {
          com.setState({
            activeExaminationQueue: [],
            myOffice: '',
            mySpecialities: '',
            doctorActive: !doctorActive,
            my_examination_start_time: '',
          });
          RIS._me.active_examination_queue = [];
          // com.setState({
          //     queue: data
          // });
          // RIS._me.active_queue = '';
          // self.getMyTicket(setUser);
        });
      } else {
        Api.call('make-examination-queue', {
          method: 'post',
          post: {
            examination_id: com.state.id,
            specialities: com.state.mySpecialities.map((el) => {
              return self.getSpecialityByName(el);
            }),
            office_id: com.state.myOffice,
            reg: com.state.id,
          }
        }).then((data) => {
          com.setState({
            activeExaminationQueue: data,
            myOffice: data.office_id,
            mySpecialities: data.specialities,
            doctorActive: !doctorActive,
            my_examination_start_time: data.updated_at_format,
          });
          RIS._me.active_examination_queue = data;

          // Если есть талоны в очереди, то посылаем команду на приглашение следующего пациента
          if (RIS._me.my_wait_wait_examination_ticket && RIS._me.all_examination_tickets.length) {
            self.inviteNextExaminationTicket()
          }
          // RIS._me.registry_location = '/registries/' + com.state.item.id,
          // RIS._me.current_registry = {
          //     id: com.state.item.id,
          //     orig_id: com.state.item.orig_id,
          //     critical_duration: com.state.item.critical_duration
          // },
          // self.getMyTicket(setUser);
        });
      }
    },
    getSpecialityByName: (name) => {
      return com.state.item.specialities.find((i) => i.title === name);
    },
    // Действие кнопки "Пришел на прием"
    repeatExaminationTicket: (id) => {
      if (com.state.repeatExaminationAvailable) {
        self.setState('repeatExaminationAvailable', false);
        Api.call('repeat-examination-ticket', {
          method: 'post',
          post: {
            examination_queue_uuid: RIS._me.active_examination_queue.uuid,
            examination_ticket_id: id,
            reg: RIS._me.current_examination.id,
          }
        }).then(() => {
          setTimeout(function () {
            self.setState('repeatExaminationAvailable', true);
          }, 10 * 1000)
        });
      }
    },
    // Вернуть талон в очередь без отметки о прохождении
    returnToQueueExaminationTicket: (id) => {
      if (com.state.returnToQueueExaminationTicketAvailable) {

        Api.call('return-to-queue-examination-ticket', {
          method: 'post',
          post: {
            examination_ticket_id: id,
            reg: RIS._me.current_examination.id,
          }
        }).then(() => {
          setTimeout(function () {
            self.setState('returnToQueueExaminationTicketAvailable', true);
          }, 10 * 1000)
        });
      }
    },
    // Вернуть тикет из потеряшек
    restoreExaminationTicket: (id) => {
      Api.call('restore-examination-ticket', {
        method: 'post',
        post: {
          examination_ticket_id: id,
          reg: RIS._me.current_examination.id,
        }
      });
    },
  });
};

export default Component('ExaminationDetail', View);
