import React from 'react';
import {useHistory} from 'react-router-dom';

export default function Link(props) {
  props = props || {};
  const history = useHistory();
  const { back, openInNewTab, simple, disabled, url, href, className = '', ...rest } = props;

  const actualUrl = url || href || '#';

  const go = (e) => {
    if (!simple) {
      e.preventDefault();
      if (disabled) return;
      if (back) {
        history.goBack();
        return;
      }
      if (openInNewTab) {
        window.open(actualUrl, "_blank", "noreferrer");
        return;
      }
      history.push(actualUrl);
    }
  };

  const linkClassName = `${className} ris-link${back ? ' back' : ''}${disabled ? ' disabled' : ''}`;

  return (
    <a {...rest} className={linkClassName} href={actualUrl} onClick={go}>
      {props.children}
    </a>
  );
}

