import React from 'react';
import Dropzone from 'react-dropzone';
import Component from '../util/Component';
import Loader from './Loader';
import Grid from '@material-ui/core/Grid';


import PublishIcon from '@material-ui/icons/Publish';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const EIView = (props) => {
    let {
        value,
        loading,
        disabled
    } = props.state;
    let self = props.self;
    let classes = [
        'editable-image',
        ...(loading ? [ 'uploading' ] : []),
    ];
    return (
        <div className={classes.join(' ')}>
            <div className='wrapper'>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className='img'>
                            <Loader active={loading} />
                            <div className='bg' style={{ backgroundImage: "url('"+(value || '/assets/images/no_photo.jpg')+"')" }}></div>
                        </div>
                    </Grid>
                    <Grid item xs={value ? 6 : 12} style={{ textAlign: 'center' }}>
                        <Dropzone disabled={disabled} onDropAccepted={files => self.onDrop(files)} accept={self.props.accept || '*/*'} maxFiles={1}>
                        {({getRootProps, getInputProps}) => (
                            <div {...getRootProps({className: 'dropzone'})}>
                                <input {...getInputProps()} />
                                <Tooltip title={value ? 'Заменить' : 'Загрузить'}>
                                    <IconButton disabled={disabled} className='upload' aria-label="upload">
                                        <PublishIcon />
                                    </IconButton>
                                </Tooltip>
                            </div>
                        )}
                        </Dropzone>
                    </Grid>
                    { value ? (
                    <Grid item xs={6} style={{ textAlign: 'center' }}>
                        <Tooltip title="Удалить">
                            <IconButton disabled={disabled} className='clear' onClick={(e) => self.onClearClick(e)} aria-label="delete">
                                <DeleteIcon />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    ) : ''}
                </Grid>
            </div>
        </div>
    );
}

class EditableImage extends Component {
    constructor (props) {
        super(props);
        this.state = {
            loading: false,
            value: props.value || '',
            disabled: props.disabled || false
        };
        this.view = EIView;
        this.spyFields.push('value');
    }
    componentDidUpdate(prev) {
        this.spy(prev);
    }
    onDrop(files) {
        if (this.state.disabled)
            return;
        this.upload(files);
    };
    onClearClick(e) {
        e.preventDefault();
        if (this.state.disabled)
            return;
        this.upload([]);
    }
    upload(files) {
        let self = this;
        if (!files.length) {
            self.setState({
                loading: false,
                value: ''
            });
            self.props.onChange ? self.props.onChange('') : 0;
            return;
        }
        self.setState({ loading: true });
        Api.upload({
            section: self.props.section || 'tmp',
            filename: files[0].name,
            file: files[0]
        }).then((data) => {
            let v = false;
            if (data && data.download) {
                v = data.download;
            }
            self.setState({
                loading: false,
                value: v
            });
            self.props.onChange ? self.props.onChange(v) : 0;
        });
    }
}

export default EditableImage;