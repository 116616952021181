import React from 'react';

export default function Logo(props) {
  // props.fill = props.fill || '#00A7B5';
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 155 21'
      width='155'
      height='21'
      {...props}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M21.1061 16.256V19.09C25.3676 19.09 29.636 19.09 33.8974 19.09C35.4495 19.09 37.6322 19.09 38.7963 17.822C39.2236 17.4419 39.5531 16.9646 39.7569 16.4303C39.9608 15.896 40.0329 15.3204 39.9674 14.7523C40.0089 12.4726 40.0782 10.9552 37.4451 10.0544V9.97814C39.4477 9.34759 39.3853 7.77466 39.3853 6.01465C39.4247 5.47559 39.3375 4.93478 39.1305 4.43546C38.9236 3.93615 38.6027 3.49217 38.1935 3.13904C37.1541 2.07888 34.8536 1.93336 33.5648 1.93336H21.0923V16.256H21.1061ZM25.2082 15.8749V11.9945H34.2162C35.304 11.9945 35.8722 12.4865 35.8999 13.9763C35.8999 15.6809 34.6735 15.8541 34.2439 15.8541L25.2082 15.8749ZM25.2082 8.85562V5.22473H33.648C34.7289 5.22473 35.2486 6.00772 35.2486 7.14411C35.2515 7.57658 35.0899 7.994 34.7966 8.31179C34.5032 8.62958 34.1001 8.82398 33.6687 8.85562H25.2082Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M47.0005 19.0902V14.0111H56.3064C57.3874 13.845 58.3951 13.3628 59.2028 12.6252C60.5401 11.475 60.651 8.66175 60.5886 7.33135C60.4847 5.05165 59.8957 4.08156 59.2028 3.28471C58.1583 2.36293 56.8052 1.86817 55.4125 1.89887C51.2203 1.89887 47.0213 1.89887 42.8291 1.89887V19.0902C44.2149 19.0902 45.6146 19.0902 47.0282 19.0902H47.0005ZM47.0005 10.6712V5.40503H50.7145C51.3035 5.40503 54.1237 5.35653 54.678 5.40503C55.2323 5.45354 56.5073 6.03559 56.4727 8.12127C56.438 10.207 55.1769 10.4356 54.5533 10.5326C53.9296 10.6296 51.3035 10.6435 50.666 10.6435H46.9866L47.0005 10.6712Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M135.569 19.1385H147.903C150.606 19.1385 152.227 19.1385 153.918 17.3023C155.047 16.0689 154.999 13.7615 154.999 10.3731C154.999 7.39357 155.089 4.98914 153.786 3.53401C152.213 1.774 149.629 1.91258 147.432 1.91258H135.583L135.569 19.1385ZM139.727 5.40489H141.556C144.328 5.40489 148.901 5.08615 149.947 6.00773C150.994 6.92931 150.917 8.65468 150.883 11.2115C150.883 13.6506 150.779 14.2257 150.128 14.981C149.157 16.1035 143.309 15.7294 141.889 15.7294H139.72V5.40489H139.727Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M132.41 19.0831C132.736 18.9612 133.01 18.7305 133.185 18.43C133.36 18.1296 133.427 17.7777 133.373 17.4339C133.407 17.0899 133.332 16.7439 133.159 16.4444C132.987 16.1449 132.724 15.907 132.41 15.764H119.182V11.9183H130.047C130.36 11.8136 130.63 11.6061 130.811 11.3295C130.992 11.0528 131.074 10.7232 131.045 10.3939C131.088 10.0614 131.01 9.72432 130.824 9.44528C130.638 9.16623 130.357 8.96412 130.033 8.8764H119.182V5.1H132.347C132.681 4.99331 132.968 4.77716 133.163 4.48661C133.358 4.19606 133.449 3.84816 133.421 3.49936C133.446 3.15533 133.353 2.81314 133.158 2.52871C132.963 2.24427 132.677 2.03439 132.347 1.93336H115.024V19.09L132.41 19.0831Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M74.1424 2.00283e-05C75.908 -0.00348313 77.6441 0.452627 79.18 1.32349L79.6442 1.62838C77.0735 3.74178 67.9062 11.253 64.6148 13.9554C64.1538 12.7739 63.9188 11.5165 63.9219 10.2483C63.9219 7.53507 64.9978 4.93257 66.9137 3.01145C68.8297 1.09032 71.4292 0.00735799 74.1424 2.00283e-05ZM80.5311 2.23814C80.7529 2.4183 80.9746 2.60539 81.1825 2.79941C81.3904 2.99343 81.522 3.13894 81.6883 3.3191L73.0407 20.4688C72.9922 20.4688 72.2438 20.3164 72.2023 20.3094C70.677 20.0092 69.2397 19.3668 67.9987 18.4306C66.7576 17.4944 65.7452 16.2888 65.0375 14.9047L80.5589 2.23814H80.5311ZM82.4575 4.1991C83.5795 5.72915 84.2544 7.54081 84.4069 9.43203C84.5595 11.3232 84.1836 13.2196 83.3213 14.9097C82.459 16.5998 81.1442 18.0172 79.5234 19.0036C77.9027 19.9901 76.0398 20.507 74.1424 20.4965L82.4575 4.1991Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M17.3576 19.0831C17.6848 18.9624 17.9602 18.7323 18.137 18.4317C18.3138 18.1311 18.3812 17.7786 18.3277 17.4339C18.3599 17.0892 18.2838 16.7431 18.1099 16.4437C17.936 16.1444 17.673 15.9068 17.3576 15.764H4.15751V11.9183H14.9809C15.2977 11.8163 15.5707 11.6102 15.7556 11.3334C15.9405 11.0567 16.0264 10.7256 15.9995 10.3939C16.0427 10.0604 15.9632 9.72263 15.7758 9.44344C15.5884 9.16425 15.3059 8.96272 14.9809 8.8764H4.15751V5.1H17.323C17.6548 4.99118 17.9408 4.77453 18.1354 4.48451C18.33 4.19448 18.4221 3.84772 18.397 3.49936C18.4194 3.15378 18.3241 2.8108 18.1265 2.52638C17.9289 2.24197 17.6408 2.03292 17.3091 1.93336H0V19.0831H17.3576Z'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M111.442 19.0344C111.442 17.9604 111.442 15.0017 111.442 13.2347C111.442 9.82555 111.504 5.98679 111.442 3.8041C111.4 2.41826 111.061 1.97479 109.737 1.94708C109.274 1.93857 108.812 1.95708 108.351 2.00251C108.164 1.99652 107.979 2.0386 107.812 2.12473C107.646 2.21086 107.505 2.33816 107.402 2.49448L103.84 6.97073L99.9877 11.6964C97.3962 8.51594 95.0749 5.73734 92.5319 2.54299C92.4308 2.38857 92.2916 2.26282 92.1278 2.17786C91.9639 2.0929 91.7809 2.0516 91.5965 2.05794C91.1362 2.00939 90.6733 1.99088 90.2106 2.00251C89.1782 2.00251 88.5338 2.37669 88.5269 4.16441C88.5269 6.34711 88.5269 9.88099 88.5269 13.2832C88.5269 15.0571 88.5269 17.9604 88.5269 19.0344H92.6497V8.77232L95.4699 12.1122L98.5464 15.7639C98.9276 16.2558 100.826 16.5122 101.436 15.7639C103.348 13.4218 105.434 10.9134 107.305 8.67531V19.0691L111.442 19.0344Z'
      />
    </svg>
  );
}
