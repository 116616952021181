import React from 'react';
import RisComponent from '../util/RisComponent';

import { CKEditor } from '@ckeditor/ckeditor5-react';
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import {CopyToClipboard} from 'react-copy-to-clipboard';

import {
    Button,
} from '@material-ui/core';


window.CKEDITOR_TRANSLATIONS = window.CKEDITOR_TRANSLATIONS || {};
window.CKEDITOR_TRANSLATIONS['ru'] = {
    dictionary: {
        'Save': 'Сохранить',
        'Cancel': 'Отмена',
        'Link': 'Ссылка',
            'Link URL': 'URL',
            'Open link in new tab': 'Открыть в новой вкладке',
            'Edit link': 'Изменить ссылку',
            'Unlink': 'Удалить ссылку',
        'Insert image': 'Вставить изображение',
        'Insert table': 'Вставить таблицу',
        'Column': 'Столбец',
            'Header column': 'Заголовок',
            'Insert column left': 'Добавить слева',
            'Insert column right': 'Добавить справа',
            'Delete column': 'Удалить',
            'Select column': 'Выделить',
        'Row': 'Строка',
            'Header row': 'Заголовок',
            'Insert row above': 'Добавить выше',
            'Insert row below': 'Добавить ниже',
            'Delete row': 'Удалить',
            'Select row': 'Выделить',
        'Merge cells': 'Объединить',
            'Merge cell up': 'Вверх',
            'Merge cell down': 'Вниз',
            'Merge cell right': 'Вправо',
            'Merge cell left': 'Влево',
            'Split cell vertically': 'Разбить вертикально',
            'Split cell horizontally': 'Разбить горизонтально',
        'Insert paragraph before block': 'Добавить перенос',
        'Insert paragraph after block': 'Добавить перенос',
    }
};

class EditorUploadAdapter {
    constructor( loader ) {
        this.loader = loader;
    }
    upload() {
        return this.loader.file
            .then( file => new Promise((resolve, reject) => {
                Api.upload({
                    filename: file.name, section: 'attachment', file: file
                }).then(rsp => {
                    resolve({ default: rsp.download });
                }).catch(() => reject('Upload failed'));
            }));
    }
    abort() {}
}

function EditorUploadAdapterPlugin ( editor ) {
    editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
        return new EditorUploadAdapter( loader );
    };
}

RIS.controllers.InfoField = function(com) { 
    let self = this,
        $p = com.props || {}; 
    com.state = {
        value: com.props.value,
        allow_attachments: (typeof com.props.attachments != 'undefined' && Array.isArray(com.props.attachments)),
        attachments: com.props.attachments || []
    };
    extend(self, {
        init: () => {},
        editor: null,
        onChange: ( event, editor ) => {
            const data = editor.getData();
            if (typeof com.props.onChange == 'function')
                com.props.onChange(data);
        },
        config: {
            extraPlugins: [ EditorUploadAdapterPlugin ],
            removePlugins: ['ImageCaption', 'ImageStyle', 'ImageToolbar','MediaEmbed'],
            toolbar: [
                'link',
                'uploadImage',
                'insertTable'
            ],
            toolbarPosition: 'bottom',
            table: {
                contentToolbar: [ 'tableColumn', 'tableRow', 'mergeTableCells' ]
            },
            language: 'ru',
            placeholder: $p.placeholder || $p.label || '',
            image: {
                upload: {
                    type: [ 'jpeg', 'png', 'gif'  ]
                }
            },
            pdf: {
            },
            ...($p.variant == 'full' ? {
                removePlugins: [],
                toolbar: [
                    'heading', '|',
                    'alignment', '|',
                    'bold', 'italic', 'strikethrough', 'underline', '|',
                    'link', '|',
                    'bulletedList', 'numberedList',
                    '-', // break point
                    'fontfamily', 'fontsize', 'fontColor', 'fontBackgroundColor', '|',
                    'insertTable', '|',
                    'uploadImage', '|',
                    'undo', 'redo'
                ]
            } : {})
        },
        getAttributes: () => {
            let $p = com.props || {};
            let attrProps = [
                'disabled', 'readOnly', 'id', 
                'tabindex', 'value',
            ];
            let result = {};
            attrProps.forEach(name => {
                if (typeof $p[name] != 'undefined')
                    result[name] = $p[name];
            });
            result.data = result.value || '';
            result.onChange = self.onChange;
            result.placeholder = result.label || '';
            return result;
        },
        setAttachments: (newAttachments) => {
            com.setState({ attachments: newAttachments });
            if (typeof com.props.onAttachments == 'function')
                com.props.onAttachments(newAttachments);
        },
        addAttachment: (e) => {
            e && e.preventDefault();
            RIS.askForFile().then(file => {
                Api.upload({ file: file, filename: file.name, section: 'attachments', object: true }).then(data => {
                    self.setAttachments([
                        ...com.state.attachments,
                        {
                            file: data
                        }
                    ]);
                });
            });
        },
        deleteAttachment: (e, index) => {
            e && e.preventDefault();
            if (confirm('Точно удалить?')) {
                let newAttachments = com.state.attachments;
                newAttachments.splice(index, 1);
                self.setAttachments(newAttachments);
            }
        },
        moveAttachment: (e, index, up) => {
            e && e.preventDefault();
            let newAttachments = com.state.attachments,
                newIndex = up ? index - 1 : index + 1;
            if (newIndex < 0) newIndex = 0;
            if (newIndex >= newAttachments.length) newIndex = newAttachments.length - 1;
            if (index == newIndex)
                return;
            let old = newAttachments[newIndex];
            newAttachments[newIndex] = newAttachments[index];
            newAttachments[index] = old;
            self.setAttachments(newAttachments);
        }
    });
}

function InfoFieldView({ state, controller }) {
    let attr = controller.getAttributes();
    return (
        <div className={'info-field' + (attr.data.length ? ' has-data' : '')}>
            <CKEditor
                editor={ Editor }
                config={controller.config}
                onReady={ editor => {
                    /* console.log( 'Editor is ready to use!', editor ); */
                } }
                {...attr}
            />
            {state.allow_attachments ? (<div className="attachments-list">
                { state.attachments.length ? (<ul>
                    { state.attachments.map((item, index) =>
                      <li className="attachments-item" key={item.id ? `attachments-item_${item.id}` : `attachments-item_${index}`}>
                            <div className="move">
                                <a title="Выше" className="up" href="#" onClick={e => controller.moveAttachment(e, index, 1)}>&uarr;</a>
                                <a title="Ниже" className="dn" href="#" onClick={e => controller.moveAttachment(e, index)}>&darr;</a>
                            </div>
                            <div className="file">
                                <a target="_blank" href={item.file.url}>{item.file.name}</a>
                            </div>
                            <div className="buttons">
                                <CopyToClipboard text={item.file.url}>
                                    <a title="Копировать" className="copy">&#10697;</a>
                                </CopyToClipboard>
                                <a title="Удалить" className="delete" href="#" onClick={e => controller.deleteAttachment(e, index)}>x</a>
                            </div>
                        </li>) }
                    </ul>
                ) : null }
                <div className="attachments-panel">
                    <span className="label">Вложения</span><Button disabled={attr.disabled} variant="contained" size="small" onClick={e => controller.addAttachment(e)}>Добавить файл</Button>
                </div>
            </div>) : null}
        </div>
    );
};

export default class extends RisComponent {
    view = InfoFieldView;
    controller() {
        RIS.controller('InfoField', this);
    }
};