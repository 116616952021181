import React, {useContext, useEffect, useState} from 'react';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import clsx from 'clsx';
import Navigation from '../../Navigation/VerticleNav';
import {toggleNavCollapsed} from '../../../../redux/actions';
import {useDispatch, useSelector} from 'react-redux';
import {
  Grid,
  Box,
  Button,
  Typography,
} from '@material-ui/core';
import PropTypes from 'prop-types';
import useStyles from './AppSidebar.style';
import Scrollbar from '../../Scrollbar';
import AppContext from '../../../utility/AppContext';
import AppLogo from '../../../../shared/components/AppLogo';
import { Assignment, Build } from '@material-ui/icons';
import VerticalItem from '@crema/core/Navigation/VerticleNav/VerticalItem';
import { Modal } from '@mui/material';
import { green } from '@mui/material/colors';
import { red } from '@mui/material/colors';
import { Link } from 'react-router-dom';
import ShowCalls from './components/ShowCalls';


const CalcLink = () => {
    if (!window.pcinc) {
        let s = document.createElement('script');
        s.src = 'https://prof.euromed-omsk.ru/app.js';
        document.querySelector('head').appendChild(s);
        window.pcinc = true;
    }
    return (<div className="left-footer">
      <a href="#" className="calc MuiTypography-body1" onClick={(e) => { e.preventDefault(); Profosmotr.open(); }}>
        <svg className="MuiSvgIcon-root" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 512 512">
          <path d="M436,0H76C51.187,0,31,20.187,31,45v422c0,24.813,20.187,45,45,45h360c24.813,0,45-20.187,45-45V45
            C481,20.187,460.813,0,436,0z M451,467c0,8.271-6.729,15-15,15H76c-8.271,0-15-6.729-15-15V45c0-8.271,6.729-15,15-15h360
            c8.271,0,15,6.729,15,15V467z"/>
          <path d="M406,60H106c-8.284,0-15,6.716-15,15v122c0,8.284,6.716,15,15,15h300c8.284,0,15-6.716,15-15V75
            C421,66.716,414.284,60,406,60z M391,182H121V90h270V182z"/>
          <path d="M166,242h-60c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15h60c8.284,0,15-6.716,15-15v-60
            C181,248.716,174.284,242,166,242z M151,302h-30v-30h30V302z"/>
          <path d="M166,362h-60c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15h60c8.284,0,15-6.716,15-15v-60
            C181,368.716,174.284,362,166,362z M151,422h-30v-30h30V422z"/>
          <path d="M286,242h-60c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15h60c8.284,0,15-6.716,15-15v-60
            C301,248.716,294.284,242,286,242z M271,302h-30v-30h30V302z"/>
          <path d="M286,362h-60c-8.284,0-15,6.716-15,15v60c0,8.284,6.716,15,15,15h60c8.284,0,15-6.716,15-15v-60
            C301,368.716,294.284,362,286,362z M271,422h-30v-30h30V422z"/>
          <path d="M406,242h-60c-8.284,0-15,6.716-15,15v180c0,8.284,6.716,15,15,15h60c8.284,0,15-6.716,15-15V257
            C421,248.716,414.284,242,406,242z M391,422h-30V272h30V422z"/>
        </svg>
        <span>Калькулятор профосмотров</span>
      </a>
    </div>);
}

const RegistryLink = () => (
  <div className="left-footer left-footer-top">
    {RIS.user.is('admin') || RIS._me.registries.length ? (
      <VerticalItem item={{
        id: 'registries',
        title: 'Электронная очередь',
        messageId: 'ne.registries',
        type: 'item',
        iconElement: <Build className="nav-item-icon" />,
        url: '/registries'
      }} level={0} />
    ) : ''}
  </div>
)

const ExaminationLink = () => (
  <div className="left-footer left-footer-top-top">
    {RIS.user.is('admin') || RIS._me.examinations.length ? (
      <VerticalItem item={{
        id: 'examinations',
        title: 'Электронный медосмотр',
        messageId: 'ne.examinations',
        type: 'item',
        iconElement: <Assignment className="nav-item-icon" />,
        url: '/examinations'
      }} level={0} />
    ) : ''}

  </div>
)

let user;
let setUser;
let modalOpen;
let setModalOpen;
let timer = 1000;
let setTimer;
let interval;
let invitingNext;
let setInvitingNext;

const handleModalClose = (e) => {
  disconnect();
};
const refreshTicket = (id, setTicketToContext = null) => {
  Api.call('refresh-ticket', {
    method: 'post',
    post: {
        registry_id: user.current_registry.orig_id,
        ticket_id: id,
        reg: user.current_registry.id,
    }
  }).then((data) => {
    RIS._me.current_ticket = data;
    setUser(RIS._me)
    setModalOpen(false);
  });
};
const disconnect = () => {
  Api.call('disconnect-queue', {
    method: 'post',
    post: {
        queue_id: user.current_ticket.queue_id,
        registrator_id: user.orig_id,
        reg: user.current_registry.id,
    }
  }).then(() => {
    window.location.reload();
  });
};
const modalDefaultStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 450,
  backgroundColor: "white",
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const Buttons = (props) => {
  let button = '';

  if(user.current_ticket && !user.next_ticket) {
    button = <Button disabled={props.invitingNext} variant='contained' className='button-primary' onClick={() => inviteNextTicket()}>
      Закончить<br />приём
    </Button>
  }
  if(user.next_ticket && user.all_tickets.length) {
    button = <Button disabled={props.invitingNext} variant='contained' style={{ backgroundColor: RIS._me.is_new_ticket ? red[500] : "#e0e0e0" }} className='button-primary' onClick={() => inviteNextTicket()}>
      {RIS._me.is_new_ticket ? "Есть талоны в очереди" : 'Следующий'}
    </Button>
  }
  if(!user.current_ticket && !user.next_ticket && user.all_tickets.length) {
    button = <Link to={user.registry_location}>
      <Button disabled={props.invitingNext} variant='contained' style={{ backgroundColor: RIS._me.is_new_ticket ? red[500] : "#e0e0e0" }} className='button-primary'>
        {RIS._me.is_new_ticket ? "Есть талоны в очереди" : 'Новые талоны'}
      </Button>
    </Link>
  }
  if(!user.current_ticket && !user.next_ticket && !user.all_tickets.length) {
    button = <Button disabled={props.invitingNext} variant='contained' style={{ backgroundColor: green[500] }} className='button-primary'>
      Очередь пуста
    </Button>
  }

  return button;
}

const RegistryButtons = () => (
  {user, setUser} = useContext(AppContext),
  user = !user ? RIS._me : user,
  [modalOpen, setModalOpen] = useState(false),
  [timer, setTimer] = useState(),
  [invitingNext, setInvitingNext] = useState(false),
  useEffect(() => {
    setInterval(() => {
      if(user.current_ticket && modalOpen === false) {
        if(user.current_ticket.duration === 0 && (Date.now() > (Date.parse(user.current_ticket.updated_at) + (1000 * 60 * parseInt(user.current_registry.critical_duration))))){
          setModalOpen(true);
          setTimer(30);
          clearInterval(interval);
          interval = setInterval(() => {
            if(timer <= 1) {
              clearInterval(interval);
              disconnect();
            }
            setTimer(--timer);
          }, 1000);
        }
      }
    }, 5 * 1000);
  }, []),

  (user && user.registry_location && user.active_queue) ? (
    <Grid container spacing={4} className='registry-buttons'>
      {user.current_ticket ? (
        <>
        <Grid item>
          Текущий <br />
          талон
        </Grid>
        <Grid item>
          <Typography variant="h5">
            {user.current_ticket.code}
          </Typography>
        </Grid>
        </>
      ) : ''}
      <Grid item>
        <Buttons invitingNext={invitingNext} />
      </Grid>
      <Modal
        open={modalOpen}
        onClose={handleModalClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        >
        <Box style={modalDefaultStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2" style={{ textAlign: "center", color: "red" }}>
            Критическое время обслуживания
          </Typography>
          <Box style={{ textAlign: "center" }}>
            <Box>Через</Box>
            <Box style={{ fontSize: "50px" }}>
              {timer}
            </Box>
            <Box>секунд</Box>
            <Box style={{ fontSize: "20px" }}>Приём завершится автоматически</Box>
          </Box>
          <Box p={3}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <Button variant="contained" type={'direction'} onClick={(e) => {disconnect()}}>
                  Остановить приём
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button variant="contained" color="primary" type={'direction'} onClick={(e) => {refreshTicket(user.current_ticket.id);clearInterval(interval)}}>
                  Продолжить приём
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Modal>
    </Grid>
  ) : ''
);


const inviteNextTicket = () => {
  setInvitingNext(true);
  Api.call('invite-next-ticket/' + user.current_registry.orig_id, {
      method: 'post',
      post: {
          reg: user.current_registry.id,
      }
  }).then((data) => {
    RIS._me.current_ticket = data.ticket;
    RIS._me.next_ticket = data.nextTicket;
    RIS._me.is_new_ticket = false;
    setUser(RIS._me);
    setTimeout(() => {
      setInvitingNext(false);
    }, 2 * 1000);
  });
};

const AppSidebar = (props) => {
  const dispatch = useDispatch();
  const {themeMode} = useContext(AppContext);
  const navCollapsed = useSelector(({settings}) => settings.navCollapsed);

  const handleToggleDrawer = () => {
    dispatch(toggleNavCollapsed());
  };
  const classes = useStyles({themeMode});
  let sidebarClasses = classes.sidebarStandard;
  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor={props.position}
          open={navCollapsed}
          onClose={(ev) => handleToggleDrawer()}
          classes={{
            root: clsx(props.variant),
            paper: clsx(props.variant),
          }}
          style={{position: 'absolute'}}>
          <Box height='100%' className={classes.container}>
            <Box className={clsx(classes.sidebarBg, sidebarClasses)}>
              <AppLogo />
              <Scrollbar
                scrollToTop={false}
                className={classes.drawerScrollAppSidebar}>
                <Navigation />
              </Scrollbar>
              <ExaminationLink />
              <RegistryLink />
              <RegistryButtons />
              <CalcLink />
            </Box>
          </Box>
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Box height='100%' className={clsx(classes.container, 'app-sidebar')}>
          <Box className={clsx(classes.sidebarBg, sidebarClasses)}>
            <Scrollbar scrollToTop={false} className={classes.scrollAppSidebar}>
              <AppLogo />
              <Navigation />
            </Scrollbar>
              <ExaminationLink />
              <RegistryLink />
              <RegistryButtons />
              <CalcLink />
          </Box>
        </Box>
      </Hidden>
      <ShowCalls />
    </>
  );
};

export default AppSidebar;

AppSidebar.defaultProps = {
  variant: '',
  position: 'left',
};

AppSidebar.propTypes = {
  position: PropTypes.string,
  variant: PropTypes.string,
};
