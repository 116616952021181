import React from 'react';

class RisComponent extends React.Component {
    controller;
    view;
    constructor(props) {
        super(props);
        this.controller();
    }
    controller() {}
    componentDidMount() {
        if (typeof this.controller.init == 'function')
            this.controller.init();
    }
    render() {
        let View = this.view, props = {
            self: this,
            props: this.props,
            state: this.state,
            controller: this.controller
        };
        return <View {...props} />;
    }
}

export default RisComponent;