import React from 'react';

class Component extends React.Component {
    view;
    componentDidMount() {
        if (typeof this.init == 'function')
            this.init();
    }
    spyFields = [];
    spy(prev) {
        let self = this;
        self.spyFields.forEach(name => {
            if (prev[name] != self.props[name])
                self.setState({ [name]: self.props[name] })
        })
    }
    invoke(callback) {
        return setTimeout(callback, 0);
    }
    render() {
        let View = this.view, props = {
            ...this.props,
            state: this.state,
            self: this
        };
        return <View {...props} />;
    }
}

export default Component;