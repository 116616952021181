export const authRole = {
  admin: [  'admin' ],
  boss: [ 'boss', 'admin' ],
  moderator: [ 'moderator', 'boss', 'admin' ],
  editor: [ 'editor', 'moderator', 'boss', 'admin' ],
  user: [ 'user', 'editor', 'moderator', 'boss', 'admin' ],
};

export const defaultUser = {
  displayName: 'John Alex',
  email: 'demo@example.com',
  token: 'access-token',
  role: 'user',
  photoURL: 'https://via.placeholder.com/150',
};
export const initialUrl = '/dashboard'; // this url will open after login
