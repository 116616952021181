import React, {useMemo, useState, useEffect, useContext} from 'react';
import { Icon, ListItem, ListItemText } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import {AppContext, Badge, NavLink} from '../../../index';
import Box from '@material-ui/core/Box';
import { checkPermission } from '../../../utility/Utils';
import { useSelector } from 'react-redux';

const VerticalItem = ({level, item}) => {
  const { count_new_orders } = useContext(AppContext);
  const {user} = useSelector(({auth}) => auth);
  const hasPermission = useMemo(() => checkPermission(item.auth, user.role), [
    item.auth,
    user.role,
  ]);
  if (!hasPermission) {
    return null;
  }
  return (
    <ListItem
      button
      component={NavLink}
      to={item.url}
      activeClassName='active'
      className='nav-item crema-menu-item'
      exact={item.exact}>
      {item.icon && (
        <Box component='span' mr={6}>
          <Icon
            className='nav-item-icon'
            color='action'>
            {item.icon}
          </Icon>
        </Box>
      )}
      {item.iconElement && (
        <Box component='span' mr={6}>
          {item.iconElement}
        </Box>
      )}
      <ListItemText
        primary={item.title}
        className='nav-item-text'
      />
      {item.title === 'Заказы' && (
        <Box mr={1} clone>
          <Badge count={count_new_orders} color={item.color} />
        </Box>
      )}
      {item.title !== 'Заказы' && item.count && (
        <Box mr={1} clone>
          <Badge count={item.count} color={item.color} />
        </Box>
      )}

    </ListItem>
  );
};

VerticalItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string,
    icon: PropTypes.string,
    url: PropTypes.string,
  }),
};

VerticalItem.defaultProps = {};

export default withRouter(React.memo(VerticalItem));