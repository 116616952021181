import * as React from 'react';
import Badge from '@mui/material/Badge';
import {styled} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import Box from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Button} from "@material-ui/core";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import Stack from '@mui/material/Stack';
import {useContext} from 'react';
import AppContext from "../../../@crema/utility/AppContext";

const StyledBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        right: -3,
        top: 13,
        border: `2px solid ${theme.palette.background.paper}`,
        padding: '0 4px',
    },
}));

let user;
let setUser;
const Shop = (props) => {
    ({user, setUser} = useContext(AppContext))

    function totalPrice() {
        let sum = 0;
        for (const element of RIS._me.shop_services) {
            sum += element.price
        }

        return sum
    }

    const delInShop = (e, id) => {
        let newMap = []
        for (const element of RIS._me.shop_services) {
            if (element.id != id) {
                newMap.push(element)
            }
        }
        if (newMap) {
            RIS._me.shop_services = newMap;
        }
        setUser(RIS._me);
    };

    const delAll = (e) => {
        RIS._me.shop_services = [];
        setUser(RIS._me);
    };

    const [open, setOpen] = React.useState(false);

    const handleClick = () => {
        setOpen((prev) => !prev);
    };

    const handleClickAway = () => {
        setOpen(false);
    };

    const styles = {
        position: 'fixed',
        width: '500px',
        marginLeft: '-200px',
        zIndex: 1,
        padding: '8px',
        backgroundColor: 'white',
        boxShadow: 'rgb(0 0 0 / 16%) 1px 1px 4px'
    };

    return (
        <ClickAwayListener onClickAway={handleClickAway}>
            <Box sx={{position: 'relative'}}>
                <IconButton aria-label="cart" type="button" onClick={handleClick}>
                    <StyledBadge badgeContent={RIS._me.shop_services.length} color="secondary">
                        <ShoppingCartIcon/>
                    </StyledBadge>
                </IconButton>
                {open ? (
                    <Box sx={styles}>
                        <TableContainer component={Paper}>
                            <Table sx={{minWidth: 250}} size="small" aria-label="a dense table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Услуга</TableCell>
                                        <TableCell align="right">Цена</TableCell>
                                        <TableCell align="right"></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody style={{maxHeight:'500px', overflowY:'scroll', display:'table-caption'}}>
                                    {RIS._me.shop_services.map((row) => (
                                        <TableRow key={row.title}
                                                  sx={{'&:last-child td, &:last-child th': {border: 0}}}>
                                            <TableCell component="th" scope="row">
                                                <span style={{color: 'grey'}}>{row.code}</span>
                                                <p style={{
                                                    lineHeight: 1,
                                                    margin: "5px 0px"
                                                }}>{row.title.length > 60 ? row.title.substring(0, 60) + '...' : row.title}</p>
                                            </TableCell>
                                            <TableCell align="right">{row.price}₽</TableCell>
                                            <TableCell align="right">
                                                <Button onClick={(e) => delInShop(e, row.id)}>
                                                    <DeleteForeverIcon/>
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <p style={{marginTop: '10px'}}>
                            <span style={{fontWeight: 'bold', marginLeft: '2px'}}>Итого:</span>
                            <span style={{float: 'right', fontSize: '20px'}}>{totalPrice()}₽</span>
                        </p>
                        <Stack direction="row" spacing={22} style={{marginTop: '10px'}}>
                            <Button variant="outlined" style={{fontSize: '12px'}} onClick={(e) => delAll(e)}>Очистить
                                корзину</Button>
                            <Button variant="contained" style={{fontSize: '12px'}}>Оформить заказ</Button>
                        </Stack>
                    </Box>
                ) : null}
            </Box>
        </ClickAwayListener>
    );
}

export default Shop;