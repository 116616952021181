import React from 'react';

export default function StateBadge({ complete, moderation }) {
    let cls = [ 'complete-state-badge', 'moderation' ],
        text = 'На модерации';
    if (!moderation) {
        cls[1] = complete ? 'complete' : 'incomplete';
        text = complete ? 'Заполнено' : 'Не заполнено';
    }
    return <span className={cls.join(' ')}>{text}</span>;
}