export function formatDuration(seconds, full = false) {
  // Проверка, является ли seconds числом
  if (typeof seconds !== 'number' || isNaN(seconds)) {
    console.error('Параметр seconds переданный в метод formatDuration не является числом!!!')
    return "00:00:00"; // Возвращаем "00:00:00" в случае, если seconds не является числом
  }
  const hours = Math.floor(seconds / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);
  const remainingSeconds = Math.floor(seconds % 60);

  const formattedHours = full ? String(hours).padStart(2, '0') + ':' : '';
  const formattedMinutes = String(minutes).padStart(2, '0') + ':';
  const formattedSeconds = String(remainingSeconds).padStart(2, '0');


  return `${formattedHours}${formattedMinutes}${formattedSeconds}`;
}

export function isDefinedAndNotEmpty(value) {
  // Проверяем, определено ли значение и не равно ли оно null
  if (value === null || typeof value === 'undefined') {
    return false;
  }

  // Проверка для массивов и строк
  if (typeof value === 'string' || Array.isArray(value)) {
    return value.length > 0;
  }

  // Проверка для чисел
  if (typeof value === 'number') {
    return !isNaN(value);  // Убеждаемся, что число не NaN
  }

  // Проверка для объектов (исключая null, который уже был проверен)
  if (typeof value === 'object') {
    return Object.keys(value).length > 0;  // Объект не пустой, если у него есть ключи
  }

  // Проверка для булевых значений (true/false автоматически не "пустые", но можно изменить логику при необходимости)
  if (typeof value === 'boolean') {
    return true;  // Все булевы значения считаем "не пустыми"
  }

  return true;  // Для всех других данных предполагаем, что они "не пустые"
}