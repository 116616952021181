import AppContext from '@crema/utility/AppContext'
import {Box} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import {useContext} from 'react'
import {Link} from 'ris/common'
let user
let setUser

const ShowCalls = () => (
  ({user, setUser} = useContext(AppContext)),
  (user = !user ? RIS._me : user),
  (
    <div className='calls-notifications'>
      {user && user.calls ? (
        user.calls.data && user.calls.data.length
        ? user.calls.data.map(
            patient => (
              patient.show === undefined ? (patient.show = true) : '',
              patient.show ? (
                <Box
                  key={patient.id}
                  className='patient-item'
                  display='flex'
                  justifyContent='space-between'>
                  <Box className='item'>
                    <Box>Входящий звонок от:</Box>
                    <Box>
                      <b>
                        <Link href={'/patient/' + patient.id }>
                          {patient.lastname} {patient.firstname}{' '}
                          {patient.secondname}
                        </Link>{' '}
                      </b>
                      {patient.id}
                    </Box>
                  </Box>
                  <Box
                    className='close'
                    onClick={() => {
                      patient.show = false
                      setUser(RIS._me)
                    }}>
                    <Close />
                  </Box>
                </Box>
              ) : (
                ''
              )
            ),
          )
        : (user.calls.show === undefined ? (user.calls.show = true) : '',
          user.calls.show && user.calls.phone ? (
            <Box
              key={user.calls.phone}
              className='patient-item'
              display='flex'
              justifyContent='space-between'>
              <Box className='item'>
                <Box>Входящий звонок от:</Box>
                <Box>
                  <b>
                    <Link href={'/dashboard/patients'}>{user.calls.phone}</Link>
                  </b>
                </Box>
              </Box>
              <Box
                className='close'
                onClick={() => {
                  user.calls.show = false
                  setUser(RIS._me)
                }}>
                <Close />
              </Box>
            </Box>
          ) : (
            ''
          ))
      ) : ''}
    </div>
  )
)

export default ShowCalls
