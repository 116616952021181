import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from '@material-ui/core/styles';
import Logo from '../../../ris/common/Logo';

const AppLogo = (props) => {
  const useStyles = makeStyles(() => ({
    logoRoot: {
      display: 'flex',
      flexDirection: 'row',
      cursor: 'pointer',
      alignItems: 'center',
      justifyContent: 'center'
    },
    logo: {
      height: 36,
      marginRight: 10,
    },
  }));
  const classes = useStyles();
  return (
    <Box className={classes.logoRoot}>
        <Logo className={classes.logo} fill="#0a8fdc" />
    </Box>
  );
};

export default AppLogo;
