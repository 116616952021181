import React from 'react';
import TextField from '@material-ui/core/TextField';

let num = 0;
export default function Editable(props) {
    const [prevPropsValue, setPValue] = React.useState(props.value || ''),
          pv = props.value || '';
    const [value, setValue] = React.useState(props.value || '');
    const [focus, setFocus] = React.useState(false);
    const [mouse, setMouse] = React.useState(false);
    const [edit, setEdit] = React.useState(false);
    const { disabled = false, readOnly = false } = props;
    const [n, setN] = React.useState(0);
    if (!n) {
        num++;
        setN(num);
    }
    const id = props.id || 'editable-'+n,
        name = props.name || 'editable-'+n,
        label = props.label || '',
        placeholder = props.placeholder || '',
        type = props.type || 'text',
        style = props.style || {};
    const handleChange = (event) => {
        setValue(event.target.value);
        props.onChange && props.onChange(event.target.value);
    };
    const handleUI = (m, f) => (event) => {
        setMouse(m);
        setFocus(f);
        setEdit(!disabled && !readOnly && (f || m));
    };
    if (pv != prevPropsValue) {
        setPValue(pv);
        setValue(pv);
    }
    let cls = [
        'editable',
        edit ? 'editable-field' : 'editable-holder',
        focus ? 'focus' : '',
        mouse ? 'hover' : ''
    ];
    if (props.className)
        cls.push(props.className);
    if (props.multiline)
        cls.push('multiline');
    cls = cls.filter(item => !!item);
    return (
        <>
            <TextField
                id={id}
                type={type}
                name={name}
                label={label}
                placeholder={placeholder}
                value={value}
                disabled={disabled}
                readOnly={readOnly || disabled}
                className={cls.join(' ')}
                style={style}
                onChange={handleChange}
                margin='normal'
                multiline={props.multiline || false}
                maxRows={props.multiline ? 8 : 1}
                onMouseEnter={handleUI(1,focus)}
                onMouseLeave={handleUI(0,focus)}
                onFocus={handleUI(mouse,1)}
                onBlur={handleUI(mouse,0)}
                InputProps={props.InputProps || {}}
                fullWidth
            />
        </>
    );
}