import {useDispatch, useSelector} from 'react-redux';
import {UPDATE_AUTH_USER} from '../../shared/constants/ActionTypes';
import {useEffect, useState} from 'react';
import {
  fetchStart,
  fetchSuccess,
  //onGetLoggedInCognitoUser,
  //setJWTToken,
} from '../../redux/actions';
/*
import {AuthType} from '../../shared/constants/AppEnums';
import {defaultUser} from '../../shared/constants/AppConst';
//import jwtAxios from '../services/auth/jwt-auth/jwt-api';
*/

export const useAuthToken = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const {user} = useSelector(({auth}) => auth);

  useEffect(() => {
    const validateAuth = async () => {
      dispatch(fetchStart());
      const token = localStorage.getItem('ris.token');
      let user;
      try {
          user = JSON.parse(localStorage.getItem('ris.user'));
      } catch (e) {
          user || false;
      };
      if (!token || !user) {
        dispatch({
          type: UPDATE_AUTH_USER,
          payload: null,
        });
        dispatch(fetchSuccess());
        return;
      }
      let role = 'user',
          r = user.roles.pop();
      if (r) switch (r.id) {
          case '67832d3c-c0c4-49c3-9abd-c694b97ca2ff': role = 'editor'; break;
          case '7dd79e01-4054-4db0-a33f-2bcbeffe0757': role = 'moderator'; break;
          case 'b6b894fa-11b9-4d35-bf5c-d87efcd23d84': role = 'boss'; break;
          case '870ae809-3f09-4e45-9c9e-8bf8979e360a': role = 'admin'; break;
      }
      dispatch({
        type: UPDATE_AUTH_USER,
        payload: {
          authType: 'ris',
          displayName: user.surname + ' ' + user.name,
          fullname: user.fullname,
          name: user.name,
          surname: user.surname,
          patronymic: user.patronymic,
          subdivision: user.subdivision,
          user_position: user.user_position,
          employment_type: user.employment_type,
          email: '-',
          phone: user.phone,
          role: role,
          token: token,
          photoURL: user.photo || '/assets/images/no_photo.jpg',
        },
      });
      dispatch(fetchSuccess());
      return;
    };
    /*
    const validateAuth = async () => {
      dispatch(fetchStart());
      const token = localStorage.getItem('token');
      console.log(token);
      if (!token) {
        dispatch(fetchSuccess());
        return;
      }
      dispatch(setJWTToken(token));
      try {
        const res = await jwtAxios.get('/auth');
        dispatch(fetchSuccess());
        dispatch({
          type: UPDATE_AUTH_USER,
          payload: {
            authType: AuthType.JWT_AUTH,
            displayName: res.data.name,
            email: res.data.email,
            role: defaultUser.role,
            token: res.data._id,
            photoURL: res.data.avatar,
          },
        });
        return;
      } catch (err) {
        dispatch(fetchSuccess());
        return;
      }
    };
    */
    const checkAuth = () => {
      Promise.all([validateAuth()]).then(() => {
        setLoading(false);
      });
    };
    checkAuth();
  }, [dispatch]);

  return [loading, user];
};

export const useAuthUser = () => {
  const {user} = useSelector(({auth}) => auth);

  if (user) {
    return {id: 1, ...user};
  }
  return [null];
};
