import React from 'react';
import RModal from '@material-ui/core/Modal';

let num = 0;
export default function Modal(props) {
  const [open, setOpen] = React.useState(false);
  const [title, setTitle] = React.useState(props.title || '');
  const [content, setContent] = React.useState(props.content || props.children || '');
  num++;
  const id = props.id || ('modal-'+num);
  let self = this;
  const handleOpen = () => {
    setOpen(true);
    props.onOpen ? props.onOpen(self) : 0;
    return self;
  };

  const handleClose = () => {
    setOpen(false);
    props.onClose ? props.onClose(self) : 0;
    return self;
  };
  if (props.id) {
      window.modals = window.modals || {};
      let m = {
        instance: self,
        open: () => handleOpen(),
        close: () => handleClose(),
        content: (value) => ((value == null) ? content : (setContent(value), m)),
        title: (value) => ((value == null) ? title : (setTitle(value), m))
      };
      window.modals[id] = m;
  }

  return (
      <RModal
        open={open}
        id={id}
        onClose={handleClose}
        aria-labelledby={id+'-title'}
        aria-describedby={id+'-description'}
      >
      <div style={
        {
          background: '#fff',
          left: '50%',
          top: '50%',
          transform: 'translate(-50%, -50%)',
          position: 'fixed',
          padding: '15px 30px',
          width: '90%',
          maxWidth: '400px'
        }
      }>
        <h2 id={id+'-title'}>{title}</h2>
        <p id={id+'-description'}>
          {content}
        </p>
      </div>
      </RModal>
  );
}