import React from 'react';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import Link from './Link';

export default function Breadcrumbs(props) {
    props = props || {};
    let { path = [] } = props;
    return (
        <div className="ris-breadcrumbs">
            {path.map((item, i) => (
                <Link key={i} className='item' {...item}>
                    { i ? '' : <KeyboardBackspaceIcon /> }
                    <span> {item.text}</span>
                </Link>
            ))}
        </div>
    );
}